import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import aboutImg from "../../assets/img/home2/about-cover.jpg";
// import SustainabilityImg from "../Images/Sustainability.jpg";
import ProjectIdeaImg from "../Images/ProjectIdeaImg.png";

export default function Main() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section className="about-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 mt-5 mt-lg-0 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span style={{ fontSize: "17px" }}>
                    Turn Your Vision into Reality!
                  </span>
                  <h2>Share Your Project Idea</h2>
                </div>
                {/* <blockquote>Innovation in Action</blockquote> */}
              </div>

              <p style={{ textAlign: "justify", fontSize: "17px" }}>
                Share your project idea with us, and our expert team at Bharat
                Soft Suppliers will get back to you promptly. Let's collaborate
                to create innovative and tailored software solutions that meet
                your unique business needs.
              </p>
              <Link to="/contact-form">
                <button
                  className="btn btn-primary p-4 mt-4"
                  onClick={ScrollToTop}
                  style={{ backgroundColor: "#14254C" }}
                >
                  Fill the contact form
                </button>
              </Link>
            </div>
            <div className="col-xl-6 col-12 pe-xl-0">
              <div
                className="about-cover-bg bg-cover me-xl-5"
                style={{ backgroundImage: `url(${ProjectIdeaImg})` }}
              >
                {/* <div className="our-experience-years shadow-lg">
                  <div className="year-outline">
                    <h2>48</h2>
                  </div>
                  <p>
                    Years of <span>Experience</span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <h2 className="section-rotate-title-sustainability d-none d-xxl-block">
          PROJECT
        </h2> */}
      </section>
    </>
  );
}
