import React from "react";
// import FooterTwo from '../Components/Footer/FooterTwo'
// import HeaderOne from '../Components/Header/HeaderOne'
// import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
// import AboutOne from "../Components/About/AboutOne";
import AboutTwo from "../Components/About/AboutTwo";
// import AboutBanner from "../Components/About/AboutBanner";
// import Timeline from "../Components/Timeline";
// import TeamThree from "../Components/Team/TeamThree";
// import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
// import Skill from "../Components/Skills/Skill";
// import Facts from "../Components/FunFact/FunFact";
import FunFact from "../Components/FunFact/FunFact";
import MissionSection from "../Components/About/Mission";
import VisionSection from "../Components/About/Vision";
import ValueSectionHead from "../Components/About/ValuesHead";
import ValueSection from "../Components/About/Values";
import Keypoints from "../Components/About/Keypoints";
import { Helmet } from "react-helmet";
import AboutBG from "../Components/Images/AboutBG.jpg";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About us - Bharat Soft Supplier</title>
        <meta name="description" content="About Us" />
      </Helmet>
      {/* <HeaderOne/> */}
      <PageBanner title="About us" bannerBg={AboutBG} currentPage="About" />
      {/* <AboutOne pt /> */}
      <AboutTwo
        subhead="About"
        head="Bharat Soft Supplier"
        text1="Every day, creative minds in the entrepreneurial and organizational spheres conceive groundbreaking ideas for digital products, with the goal of tackling specific challenges or capitalizing on emerging market opportunities. Bharat Soft Supplier is committed to aiding these forward-thinking companies. Bharat Soft Supplier facilitates the realization of their digital product concepts by linking them with the most suitable software suppliers. This matchmaking process occurs seamlessly through both our online platform and offline matchmaking services. Our online platform equips users with diverse search functions and a supplier map, empowering them to pinpoint the optimal software supplier for their requirements. Furthermore, software clients can share their projects with us, allowing us to efficiently pair them with the fitting software supplier. Moreover, our global team of account executives is poised to offer support to software suppliers and clients across the world. Whether companies are in pursuit of new customers or on the quest for the perfect supplier, they can take advantage of the benefits offered by our premium services."
        verticalText="ABOUT"
      />
      <div
        className="container-fluid text-white"
        style={{ backgroundColor: "#14254C" }}
      >
        <FunFact />
      </div>
      {/* <div className="container-fluid mt-5"></div> */}
      {/* <MissionSection /> */}
      {/* <VisionSection /> */}
      {/* <div className="container-fluid mb-5"></div> */}
      <ValueSectionHead />
      <ValueSection />
      <Keypoints />
      {/* <AboutBanner /> */}
      {/* <Timeline /> */}
      {/* <Skill /> */}
      {/* <TeamThree /> */}
      {/* <BrandCarouselThree /> */}
      {/* <FooterTwo/> */}
    </>
  );
};

export default AboutPage;
