import AboutTwo from "../Components/About/AboutTwo";
// import ServicesTwo from "../Components/Services/ServicesTwo";
// import CtaOne from "../Components/Cta/CtaOne";
// import BrandCarouselTwo from "../Components/Brands/BrandCarouselTwo";
// import ProjectsTab from "../Components/Projects/ProjectsTab";
// import FooterTwo from "../Components/Footer/FooterTwo";
import BlogTwo from "../Components/Blog/BlogTwo";
// import TeamTwo from "../Components/Team/TeamTwo";
import Innovation from "../Components/About/Innovation";
import FunFact from "../Components/FunFact/FunFact";
// import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
// import NavBar from "../Components/Header/Header";
// import TopSection from "../Components/Header/HeaderTwoTop";
import HeroTwo from "../Components/Hero/HeroTwo";
// import ProjectsTapHead from "../Components/Projects/ProjectsTabHead";
// import BrandsHeading from "../Components/Brands/BrandsHeading";
import Sustainability from "../Components/Sustainability/main";

const HomeTwo = () => {
  return (
    <>
      {/* <header className="header-wrap header-2">
        <TopSection />
      </header>
      <NavBar /> */}
      <HeroTwo />
      <AboutTwo
        subhead="About"
        head="Bharat Soft Supplier"
        text1="Every day, creative minds in the entrepreneurial and organizational spheres conceive groundbreaking ideas for digital products, with the goal of tackling specific challenges or capitalizing on emerging market opportunities. Bharat Soft Supplier is committed to aiding these forward-thinking companies. Bharat Soft Supplier facilitates the realization of their digital product concepts by linking them with the most suitable software suppliers. This matchmaking process occurs seamlessly through both our online platform and offline matchmaking services. Our online platform equips users with diverse search functions and a supplier map, empowering them to pinpoint the optimal software supplier for their requirements."
        btnClass="theme-btn mt-35"
        btnText="read more"
        verticalText="ABOUT"
      />
      {/* <ServicesTwo /> */}
      {/* <CtaOne /> */}
      {/* <TeamTwo /> */}
      {/* <ProjectsTapHead /> */}
      {/* <ProjectsTab /> */}
      <Sustainability formId="01HN157YRV2NV809SSM5DDRBSX" />
      <Innovation />
      {/* <PortfolioTwo /> */}
      {/* <BrandsHeading /> */}
      {/* <BrandCarouselTwo /> */}
      <div
        className="container-fluid text-white"
        style={{ backgroundColor: "#14254C" }}
      >
        <FunFact />
      </div>
      <BlogTwo />
      {/* <FooterTwo /> */}
    </>
  );
};

export default HomeTwo;
