import React, { useState } from "react";
// import HeaderTwoTop from "./HeaderTwoTop";
import mainLogo from "../Images/main_logo.png";
// import SearchIcon from "../Images/magnifying-glass-solid.svg";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const HeaderTwo = () => {
  const [search, setSearch] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <header className="header-wrap header-2">
      {/* <HeaderTwoTop /> */}
      <div className="main-header-wrapper">
        <div className="container-fluid align-items-center justify-content-between d-flex">
          <div className="logo wow fadeInLeft animated">
            <Link to="/" onClick={ScrollToTop}>
              <img src={mainLogo} alt="logo" height={90} width={200} />
            </Link>
          </div>
          <div className="main-menu d-none d-lg-block">
            <ul>
              <li>
                <Link to="/" onClick={ScrollToTop}>
                  Home
                </Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/">homepage 1</Link>
                  </li>
                  <li>
                    <Link to="/homeTwo">homepage 2</Link>
                  </li>
                  <li>
                    <Link to="/homeThree">homepage 3</Link>
                  </li>
                  <li>
                    <Link to="/homeFour">homepage 4</Link>
                  </li>
                </ul> */}
              </li>
              <li>
                <Link to="/company-list" onClick={ScrollToTop}>
                  Company List
                </Link>
              </li>
              <li>
                <Link to="/company-registration" onClick={ScrollToTop}>
                  Company Registration{" "}
                </Link>
              </li>
              <li>
                <Link to="/blogs" onClick={ScrollToTop}>
                  Blogs
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={ScrollToTop}>
                  about us
                </Link>
              </li>

              {/* <li>
                <Link to="/news">News</Link>
              </li> */}
              {/* <li>
                <Link to="/contact" onClick={ScrollToTop}>
                  Contact us
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="d-inline-block d-lg-none">
            <div className="mobile-nav-wrap">
              <div id="hamburger" onClick={handleMobileMenu}>
                <i className="fal fa-bars"></i>
              </div>
              <MobileMenu
                mobileMenu={mobileMenu}
                handleMobileMenu={handleMobileMenu}
              />
            </div>
            <div className="overlay"></div>
          </div>
          <div className="right-elements d-none d-xl-flex d-flex align-items-center">
            <div className="search-icon">
              <div className={search ? "search-box" : "search-box show"}>
                <form action="#">
                  <input type="text" placeholder="Search" />
                  <button type="submit">
                    <i className="fal fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="call-action text-center">
              <a href="tel:+31644905433">
                <span>Reach us at</span>
                <p>+31644905433</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
