import React from "react";
// import bannerBg from "../assets/img/page-banner.jpg";
// import BannerImg from "../Components/Images/PrivacyPolicyImg.jpg";
import PageBanner from "../Components/PageBanner";
import PageBannerImg from "../Components/Images/PrivacyPolicyImg.jpg";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Bharat Soft Supplier</title>
        <meta name="description" content="Privacy Policy" />
      </Helmet>
      <PageBanner
        title="Privacy Policy"
        bannerBg={PageBannerImg}
        currentPage="About"
      />
      <div className="container" style={{ textAlign: "justify" }}>
        <div className="row">
          <div className="col-md-12 mt-3">
            {/* <h2>Heading</h2> */}
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              At Bharat Soft Supplier, we value the privacy and confidentiality
              of our users’ information. This Privacy Policy outlines how we
              collect, use, disclose, and safeguard your data when you access
              our services, websites, or applications. By using our services,
              you consent to the practices described in this policy.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Information We Collect</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We collect information to provide a better experience and improve
              our services. This includes:
            </p>
            <ul>
              <li>
                &bull; Personal Information: When you register or use our
                services, we may collect information such as your name, email
                address, phone number, and billing information.
              </li>
              <li>
                &bull; Non-Personal Information: We may collect non-identifiable
                information, such as IP addresses, browser type, usage data, and
                demographic information.
              </li>
              <li>
                &bull; Cookies and Tracking Technologies: We use cookies, web
                beacons, and other tracking technologies to collect information
                about your interaction with our site. This helps us enhance your
                experience, analyze site traffic, and understand user
                preferences.
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>How We Use Your Information</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              The information we collect is used for various purposes,
              including:
            </p>
            <li style={{ fontSize: "17px" }}>
              <li>&bull; To provide and maintain our services.</li>
              <li>&bull; To process transactions and manage accounts.</li>
              <li>
                &bull; To communicate with you about updates, promotions, and
                service announcements.
              </li>
              <li>
                &bull; To analyze data and improve the performance of our
                services.
              </li>
              <li>
                &bull; To comply with legal obligations or protect our rights
                and interests.
              </li>
            </li>
            <p>
              In addition, we may use your information for internal
              record-keeping, compliance with legal obligations, and to protect
              our rights and interests.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Information Sharing and Disclosure</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We do not sell or rent your personal information to third parties.
              However, we may share your information under specific
              circumstances, such as:
            </p>
            <ul>
              <li>
                &bull; With service providers: We may share your data with
                trusted service providers who help us operate our business, such
                as payment processors or hosting providers.
              </li>
              <li>
                &bull; For legal purposes: If required by law or in response to
                valid legal requests, we may disclose your information to
                authorities or legal bodies.
              </li>
              <li>
                &bull; Business transfers: In case of a merger, acquisition, or
                sale of assets, your information may be transferred as part of
                the business transaction.
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Data Security</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We implement industry-standard security measures to protect your
              information from unauthorized access, disclosure, or misuse. This
              includes encryption, firewalls, and secure servers. However, no
              method of transmission over the internet or electronic storage is
              entirely secure, and we cannot guarantee absolute security.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Your Rights</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              You have certain rights regarding your personal information,
              including:
            </p>
            <li>&bull; The right to access and update your personal data.</li>
            <li>
              &bull; The right to request deletion or restriction of your data.
            </li>
            <li>
              &bull; The right to withdraw consent for data processing. To
              exercise your rights, please contact our support team.
            </li>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Changes to this Privacy Policy</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal obligations. Any modifications
              will be posted on our website with the effective date. We
              encourage you to review this policy periodically.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Contact Us</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              If you have any questions or concerns about this Privacy Policy or
              how we handle your data, please contact us at{" "}
              <a href="mailto:hallo@bellegirllifestyle.com">
                <b>hallo@bellegirllifestyle.com</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
