import React from "react";
import { Link } from "react-router-dom";

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
  return (
    <div className={mobileMenu ? "mobile-nav" : "mobile-nav show"}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          <li>
            <Link to="/" onClick={handleMobileMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/company-list" onClick={handleMobileMenu}>
              Company List
            </Link>
          </li>
          <li>
            <Link to="/company-registration" onClick={handleMobileMenu}>
              Company Registration
            </Link>
          </li>
          <li>
            <Link to="/blogs" onClick={handleMobileMenu}>
              Blogs
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={handleMobileMenu}>
              About Us
            </Link>
          </li>
        </ul>
      </nav>

      <div className="action-bar">
        <a href="mailto:hallo@bellegirllifestyle.com">
          <i className="fal fa-envelope"></i> hallo@bellegirllifestyle.com
        </a>
        <i className="fal fa-phone text-white"></i>
        <span className="text-white"> +31644806659 ,+31644905433</span>
      </div>
    </div>
  );
};

export default MobileMenu;
