import React from "react";
// import { Link } from "react-router-dom";
// import img1 from "../../assets/img/project-tab.jpg";
// import img2 from "../../assets/img/project-tab2.jpg";
// import img3 from "../../assets/img/project-tab2.jpg";
import ContactBG from "../Images/contactusbg.jpg";

function Values() {
  return (
    <section className="our-info-tabs-wrapper mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="project-tabs-wrapper">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-tab-1"
                  role="tabpanel"
                  aria-labelledby="pills-tab-1"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h1 className="text-center">Contact Details</h1>
                        <ul className="mt-5">
                          <li>
                            <i className="fas fa-phone"></i>{" "}
                            <b>Payal Gandhi: </b> +31-644806659
                          </li>
                          <li>
                            <i className="fas fa-phone"></i>{" "}
                            <b>Team Coordinator: </b> +31-644905433
                          </li>
                          <li>
                            <i class="fas fa-envelope"></i> <b>Mail us: </b>{" "}
                            hello@bellegirllifestyle.com
                          </li>
                          <li>
                            <b>Address: </b> Klifrakplantsoen 178, 3544 RC
                            Utrecht. The Netherlands
                          </li>
                          <li>KVK Number (COC): 80193935</li>
                          <li>VAT Number: NL003405066B77</li>
                          <li>A/C No (IBAN): BE90 9671 8679 9532</li>
                          <li>Account Holder: Bellegirl Lifestyle</li>
                          <li>BIC: TRWIBEB1XXX</li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={ContactBG} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Values;
