import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Realm from "realm-web";

const BlogTwo = () => {
  const [blogData, setBlogData] = useState([]); // To hold the fetched blog data
  const [selectedBlog, setSelectedBlog] = useState(null); // To hold the selected blog for modal

  // Fetch blog data from MongoDB Realm
  useEffect(() => {
    async function fetchData() {
      const app = new Realm.App({ id: "application-0-dlhopxm" }); // Your Realm App ID
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection = mongodb.db("bharatsoft").collection("blog");

        // Fetch the 3 most recent blog posts sorted by the 'date' field in descending order
        const data = await collection.find({}, { sort: { date: -1 } }); // Sorting by date, limiting to 3
        setBlogData(data); // Store the fetched data
      } catch (err) {
        console.error("Failed to fetch blog data:", err);
      }
    }
    fetchData();
  }, []);

  // Function to handle opening the modal
  const handleOpenModal = (blog) => {
    setSelectedBlog(blog);
    // Show the modal using Bootstrap's modal functionality
    const modal = new window.bootstrap.Modal(
      document.getElementById("blogModal")
    );
    modal.show();
  };

  return (
    <>
      <section className="news-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 text-center">
              <div className="block-contents">
                <div className="section-title">
                  <h5>Blog</h5>
                  <span>Our Blog Post</span>
                  <h2>Latest News</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Display latest 3 blog posts */}
            {blogData.map((blog) => (
              <div className="col-xl-4 col-md-6 col-12" key={blog._id}>
                <div className="single-blog-card style-1">
                  <div
                    className="blog-featured-img bg-cover bg-center"
                    style={{ backgroundImage: `url(${blog.blogImage})` }}
                  ></div>
                  <div className="contents">
                    <div className="post-metabar d-flex justify-content-between align-items-center">
                      <div className="post-author">
                        <div
                          className="author-img bg-cover bg-center"
                          style={{ backgroundImage: `url(${blog.authorImg})` }}
                        ></div>
                        <Link to={`/blog/${blog._id}`}>{blog.author}</Link>
                      </div>
                      <div className="post-date">
                        <i className="fal fa-calendar-alt"></i>
                        <Link to={`/blog/${blog._id}`}>
                          {new Date(blog.date).toLocaleDateString()}
                        </Link>
                      </div>
                    </div>
                    <h3>
                      <Link to={`/blog/${blog._id}`}>{blog.blogHeading}</Link>
                    </h3>
                    <Link
                      to="#"
                      className="read-more-link"
                      onClick={() => handleOpenModal(blog)} // Open modal on click
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Modal for displaying blog details */}
      <div
        className="modal fade"
        id="blogModal"
        tabIndex="-1"
        aria-labelledby="blogModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="blogModalLabel">
                {selectedBlog?.blogHeading}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedBlog && (
                <>
                  <img
                    src={selectedBlog.blogImage}
                    alt="Blog_Image"
                    style={{ width: "100%", marginBottom: "20px" }}
                  />
                  <p>
                    <strong>Date: </strong>
                    {new Date(selectedBlog.date).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Author: </strong>
                    {selectedBlog.author}
                  </p>
                  <p>
                    <strong>Content: </strong>
                    {selectedBlog.blogContent}
                  </p>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogTwo;
