import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import Banner from "../Components/Images/companyMapBanner.jpg";
import * as Realm from "realm-web";
import { Helmet } from "react-helmet";

export default function CompanyMap() {
  const [companyData, setCompanyData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null); // For storing selected company

  useEffect(() => {
    async function fetchData() {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection = mongodb.db("bharatsoft").collection("companymap");
        const data = await collection.find({});
        setCompanyData(data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    }

    fetchData();
  }, []);

  // Function to handle "View Details" click
  const handleViewDetails = (company) => {
    setSelectedCompany(company); // Set the selected company details
    const modal = new window.bootstrap.Modal(
      document.getElementById("companyModal")
    );
    modal.show();
  };

  return (
    <>
      <Helmet>
        <title>Company List - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>

      <PageBanner
        title="Company List"
        currentPage="Company List"
        bannerBg={Banner}
      />

      <div className="container-fluid mb-5 mt-3">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <div className="container-fluid">
              <div className="row card-container">
                {companyData ? (
                  companyData.map((company, index) => (
                    <div
                      className="col-md-4 d-flex align-items-stretch mt-2 mb-2"
                      key={index}
                    >
                      <div
                        className="card shadow-lg h-100"
                        style={{ margin: "10px" }}
                      >
                        <img
                          src={company.image}
                          className="card-img-top"
                          alt="Company_Logo_Image"
                          style={{ height: "150px", width: "350px" }}
                        />
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{company.companyName}</h5>
                          <p className="card-text">
                            <strong>Company Size:</strong> {company.companySize}
                          </p>
                          <p className="card-text">
                            <strong>Avg Project Size:</strong>{" "}
                            {company.avgProjectSize}
                          </p>
                          <p className="card-text">
                            <strong>Projects Completed:</strong>{" "}
                            {company.projectCompleted}
                          </p>

                          {/* Add mt-auto to the button and anchor to push them to the bottom */}
                          <button
                            className="btn text-white p-2 mt-auto"
                            onClick={() => handleViewDetails(company)}
                            style={{ backgroundColor: "#14254C" }}
                          >
                            View Details
                          </button>
                          <a
                            className="btn text-white p-2 mt-2"
                            href={company.visitWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ backgroundColor: "#14254C" }}
                          >
                            Company Website
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Loading data...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying company details */}
      <div
        className="modal fade"
        id="companyModal"
        tabIndex="-1"
        aria-labelledby="companyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyModalLabel">
                {selectedCompany?.companyName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedCompany && (
                <>
                  <img
                    src={selectedCompany.image}
                    alt="Company_Logo_Image"
                    style={{ width: "50%", marginBottom: "20px" }}
                  />
                  <p>
                    <strong>Avg Project Size: </strong>
                    {selectedCompany.avgProjectSize}
                  </p>
                  <p>
                    <strong>Projects Completed: </strong>
                    {selectedCompany.projectCompleted}
                  </p>
                  <p>
                    <strong>Company Size: </strong>
                    {selectedCompany.companySize}
                  </p>
                  <p>
                    <strong>Visit Website: </strong>
                    <a
                      href={selectedCompany.visitWebsite}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {selectedCompany.visitWebsite}
                    </a>
                  </p>
                  <p>
                    <strong>Location: </strong>
                    {selectedCompany.location}
                  </p>
                  <p>
                    <strong>About Company: </strong>
                    {selectedCompany.aboutCompany}
                  </p>
                  <p>
                    <strong>Remote Collaboration Possible: </strong>
                    {selectedCompany.remoteCollaborationPossible}
                  </p>
                  <p>
                    <strong>Development Process: </strong>
                    {selectedCompany.developmentProcess}
                  </p>
                  <p>
                    <strong>Languages: </strong>
                    {selectedCompany.languages.join(", ")}
                  </p>
                  <p>
                    <strong>Work Tags: </strong>
                    {selectedCompany.workTags.join(", ")}
                  </p>
                  <img
                    src={selectedCompany.contactPersonImage}
                    alt="Contact Person"
                    style={{ width: "100px", marginTop: "10px" }}
                  />
                  <p>
                    <strong>Contact Person: </strong>
                    {selectedCompany.contactPersonName}
                  </p>
                  <p>
                    <strong>Contact Number: </strong>
                    {selectedCompany.phoneNumber}
                  </p>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
