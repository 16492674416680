import { Swiper, SwiperSlide } from "swiper/react";
// import slide1 from "../../assets/img/home2/hero1.jpg";
// import steel from "../../assets/img/steel.jpg";
// import packaging from "../../assets/img/packaging.jpg";
// import construction from "../../assets/img/construction.jpg";
// import retail from "../../assets/img/retail.jpg";
import { EffectFade } from "swiper";
import Banner1 from "../Images/FrontPage1.jpg";
import Banner2 from "../Images/FrontPage2.jpg";
import Banner3 from "../Images/FrontPage3.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css/effect-fade";

const HeroTwo = () => {
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="hero-wrapper hero-2">
      <Swiper
        navigation={true}
        modules={[Navigation, EffectFade]}
        effect={"fade"}
        loop
      >
        <SwiperSlide className="hero-slider-2">
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: `url(${Banner1})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                  <div className="hero-contents pe-lg-3">
                    <h1
                      className="wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                    >
                      Customized <br /> IT <br /> Solutions
                    </h1>
                    <p
                      className="pe-lg-5 wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                      data-wow-delay=".4s"
                    >
                      Elevate your operations with our bespoke software
                      services. Get started today and see the difference!
                    </p>
                    <Link
                      to="/contact-form"
                      className="theme-btn me-sm-4 wow fadeInLeft"
                      data-wow-duration="1.2s"
                      data-wow-delay=".8s"
                      onClick={ScrollToTop}
                    >
                      contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="hero-slider-2">
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: `url(${Banner2})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                  <div className="hero-contents pe-lg-3">
                    <h1
                      className="wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                    >
                      Excellence in Software Development
                    </h1>
                    <p
                      className="pe-lg-5 wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                      data-wow-delay=".4s"
                    >
                      Partner with us for reliable, scalable, and secure IT
                      solutions tailored to your needs.
                    </p>
                    <Link
                      to="/company-map"
                      className="theme-btn me-sm-4 wow fadeInLeft"
                      data-wow-duration="1.2s"
                      data-wow-delay=".8s"
                      onClick={ScrollToTop}
                    >
                      Discover More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="hero-slider-2">
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: `url(${Banner3})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                  <div className="hero-contents pe-lg-3">
                    <h1
                      className="wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                    >
                      Cutting Edge Technology
                    </h1>
                    <p
                      className="pe-lg-5 wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                      data-wow-delay=".4s"
                    >
                      Empower your business with innovative software solutions
                      designed for success. Let's innovate together!
                    </p>
                    <Link
                      to="/company-registration"
                      className="theme-btn me-sm-4 wow fadeInLeft"
                      data-wow-duration="1.2s"
                      data-wow-delay=".8s"
                      onClick={ScrollToTop}
                    >
                      Get a Free Consultation
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HeroTwo;
