import React from "react";
// import bannerBg from "../assets/img/page-banner.jpg";
// import BannerImg from "../Components/Images/T&CImg.jpg";
import PageBanner from "../Components/PageBanner";
import PageBannerImg from "../Components/Images/T&CImg.jpg";
import { Helmet } from "react-helmet";

export default function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Bharat Soft Supplier</title>
        <meta name="description" content="Terms and Conditions" />
      </Helmet>
      <PageBanner
        title="Terms and Conditions"
        bannerBg={PageBannerImg}
        currentPage="About"
      />
      <div className="container" style={{ textAlign: "justify" }}>
        <div className="row">
          <div className="col-md-12 mt-3">
            {/* <h2>Heading</h2> */}
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Welcome to Bharat Soft Supplier, these Terms and Conditions govern
              your use of our services, website, and applications. By accessing
              or using our services, you agree to comply with these terms.
              Please read them carefully.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Acceptance of Terms</h2>
            <ol style={{ fontSize: "17px" }}>
              <li>
                By using our services, you acknowledge that you have read,
                understood, and agree to be bound by these Terms and Conditions.
                If you do not agree with any part of these terms, you must not
                use our services.
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Use of Services</h2>
            <ul style={{ fontSize: "17px" }}>
              <li>
                You agree to use our services only for lawful purposes and in
                compliance with all applicable laws and regulations. You are
                prohibited from:
              </li>
              <li>
                &bull; Engaging in unauthorized access, hacking, or other
                activities that may compromise the integrity of our systems.
              </li>
              <li>
                &bull; Using our services to distribute malicious software,
                spam, or any form of harmful content.
              </li>
              <li>
                &bull; Violating the intellectual property rights of Bharat Soft
                Supplier or third parties.
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>User Accounts</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              To access certain features of our services, you may be required to
              create a user account. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account. You agree to provide accurate and
              up-to-date information during the registration process.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Intellectual Property</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              All content, designs, trademarks, logos, and other intellectual
              property on our website and services are the property of Bharat
              Soft Supplier or its licensors. You may not copy, reproduce,
              modify, or distribute any content without our prior written
              consent.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Payment and Billing</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Certain services offered by Bharat Soft Supplier may require
              payment. By using paid services, you agree to provide accurate
              billing information and authorize us to charge your selected
              payment method for all applicable fees. Failure to pay these fees
              may result in the termination or suspension of your access to our
              services.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Limitation of Liability</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Bharat Soft Supplier is not liable for any direct, indirect,
              incidental, or consequential damages arising from your use of our
              services. This includes damages related to loss of data, business
              interruption, or unauthorized access to your information. We do
              not warrant that our services will be error-free, secure, or
              continuously available.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Termination of Services</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We reserve the right to terminate or suspend your access to our
              services at any time, with or without cause, and without prior
              notice. This may include violations of these Terms and Conditions,
              non-payment of fees, or other reasons deemed necessary by us.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Governing Law</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              These Terms and Conditions are governed by and construed in
              accordance with the laws. Any disputes arising from these terms
              will be subject to the exclusive jurisdiction of the courts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Changes to Terms and Conditions</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We may update these Terms and Conditions from time to time to
              reflect changes in our services or legal obligations. Any
              modifications will be posted on our website, and your continued
              use of the services after such changes constitutes acceptance of
              the revised terms.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
