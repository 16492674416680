import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../Images/about-img.jpg";

const d = new Date();
let currentYear = d.getFullYear();

const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const AboutTwo = (props) => {
  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-12 pe-xl-0">
            <div
              className="about-cover-bg bg-cover me-xl-5"
              style={{ backgroundImage: `url(${aboutImg})` }}
            >
              <div className="our-experience-years shadow-lg">
                <div className="year-outline">
                  <h2>{currentYear - 1999}</h2>
                </div>
                <p>
                  Years of <span>Experience</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mt-5 mt-lg-0 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span style={{ fontSize: "17px" }}>{props.subhead}</span>
                <h2>{props.head}</h2>
              </div>
              {/* <blockquote>Innovation in Action</blockquote> */}
            </div>

            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              {props.text1} <br />
              {props.text2} <br />
              {props.text3}
            </p>

            <Link to="/about" className={props.btnClass} onClick={ScrollToTop}>
              {props.btnText}
            </Link>
          </div>
        </div>
      </div>
      <h2 className="section-rotate-title d-none d-xxl-block">
        {props.verticalText}
      </h2>
    </section>
  );
};

export default AboutTwo;
