import React from "react";
// import img1 from "../../assets/img/why-bg.jpg";
import InnovationImg from "../Images/Innovation.jpg";
import { Link } from "react-router-dom";

const WhyUs = () => {
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="why-choice-us section-padding mb-5">
      <div
        className="why-choice-bg bg-cover d-none d-xl-block"
        style={{ backgroundImage: `url(${InnovationImg})` }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="offset-xl-6 offset-lg-4 col-lg-8 col-xl-6 col-12">
            <div className="block-contents">
              <div className="section-title">
                {/* <span>Why Choose Us</span> */}
                <h2>Connect with Us</h2>
              </div>
            </div>
            <div className="single-icon-circle-item">
              {/* <div className="icon">
                <i className="flaticon-cpu"></i>
              </div> */}
              <div className="contents">
                <h3>Let us do the work</h3>
                <p>
                  We are specialised in connecting software clients with
                  software suppliers. Get to know us, share your ideal client
                  and let us do the work for you.
                </p>
              </div>
            </div>
            <div className="single-icon-circle-item">
              {/* <div className="icon">
                <i className="flaticon-secure-shield"></i>
              </div> */}
              <div className="contents">
                <h3>Brand awareness</h3>
                <p>
                  Yearly we reach 40.000+ potential clients with our online and
                  offline marketing activities. Make use of it and put your
                  company on the radar!
                </p>
              </div>
            </div>
            <div className="single-icon-circle-item">
              {/* <div className="icon">
                <i className="flaticon-secure-shield"></i>
              </div> */}
              <div className="contents">
                <h3>New clients</h3>
                <p>
                  Daily we receive many software requests from potential clients
                  throughout the world. Make sure to keep an eye on your inbox
                  as we forward every potential match.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center mt-3">
                <Link
                  to="/company-registration"
                  className="btn btn-lg p-4"
                  style={{ backgroundColor: "#14254C", color: "white" }}
                  onClick={ScrollToTop}
                >
                  Join Now and Start Receiving Offers
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
